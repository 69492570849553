import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import DatePicker from 'vue2-datepicker';
import User from '../../../admin/user';
export default {
  name: 'meterAccessEntryForm',
  props: ['rowData'],
  components: {
    DatePicker,
    User
  },
  data() {
    return {
      unsubscribe: null,
      loader: false,
      editMode: false,
      showValueSetModal: false,
      parent_value_set_id: null,
      specIndex: null,
      user: {
        id: 0,
        name: null,
        email: null
      },
      showUserModal: false,
      userDetailsData: [
        {
          fms_user_meter_reading_uax_id: 0,
          fms_prj_id: null,
          fms_prj_name: null,
          start_date: null,
          end_date: null
        }
      ],
      userDetailsFields: [
        {
          key: 'add',
          stickyColumn: true,
          class: 'text-center'
        },
        {
          key: 'remove',
          stickyColumn: true,
          class: 'text-center'
        },
        {
          key: 'fms_prj_name',
          label: 'Project'
        },
        {
          key: 'start_date'
        },
        {
          key: 'end_date'
        }
      ],
      initialUserData: []
    };
  },
  mounted() {
    if (this.rowData) {
      this.user = {
        id: this.rowData.user_id,
        name: this.rowData.user_name,
        email: this.rowData.user_email,
        userCode :this.rowData.employee_code
      };
      this.getMeterAccessDtlsById(this.rowData.user_id);
    } else {
      this.editMode = true;
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'update') {
          this.editMode = true;
        }
        if (actionName === 'save') {
          this.addEditMeterAccessData();
        }
      }
    });
  },
  methods: {
    getMeterAccessDtlsById(userId) {
      this.loader = true;
      this.$store
        .dispatch('energyDataInput/getMeterAccessDtlsById', userId)
        .then(response => {
          if (response.status === 200) {
            this.loader = false;
            this.userDetailsData = response.data.data;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addEditMeterAccessData() {
      const userDetailsArray = this.userDetailsData.map(item => {
        return {
          fms_user_meter_reading_uax_id: item.fms_user_meter_reading_uax_id
            ? item.fms_user_meter_reading_uax_id
            : 0,
          fms_prj_id: item.fms_prj_id,
          start_date: item.start_date,
          end_date: item.end_date
        };
      });
      const payload = {
        user_id: this.user.id,
        payloadData: { add_user_meter_reading_uax: userDetailsArray }
      };
      this.$store
        .dispatch('energyDataInput/addEditMeterAccessData', payload)
        .then(response => {
          if (response.status === 201) {
            this.loader = false;
            this.editMode = false;
            this.$bvToast.toast(response.data.message, {
              title: 'Alert',
              variant: 'success',
              solid: true
            });
            this.getMeterAccessDtlsById(this.user.id);
          } else {
            this.loader = false;
            this.$bvToast.toast(response.response.data.message, {
              title: 'Alert',
              variant: 'danger',
              solid: true
            });
          }
        })
        .catch(err => {
          this.err = err;
          this.loader = false;
        });
    },
    showHideUserModal(flag) {
      this.showUserModal = flag;
    },
    selectedUser(item) {
      this.user.id = item.user_id;
      this.user.name = item.name;
      this.user.email = item.user_email;
      this.showUserModal = false;
    },
    openValueSetModal(vsetCode, index) {
      this.vsetCode = vsetCode;
      this.specIndex = index;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.FMS_PRJ_INTERNAL) {
        this.userDetailsData[this.specIndex].fms_prj_id = item.value_set_dtl_id;
        this.userDetailsData[this.specIndex].fms_prj_name = item.value_code;
      }
    },
    clearVsetValues(vsetCode, index) {
      this.vsetCode = vsetCode;
      if (this.vsetCode === appStrings.VALUESETTYPE.FMS_PRJ_INTERNAL) {
        this.userDetailsData[index].fms_prj_id = null;
        this.userDetailsData[index].fms_prj_name = null;
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    addNewRow() {
      if (this.editMode) {
        this.userDetailsData.push({
          fms_user_meter_reading_uax_id: 0,
          fms_prj_id: null,
          fms_prj_name: null,
          start_date: null,
          end_date: null
        });
      }
    },
    removeRow(index) {
      if (this.editMode) {
        this.userDetailsData.splice(index, 1);
      }
    },
    setIndex(index) {
      this.specIndex = index;
    },
    disabledDates(date) {
      const refDate = this.userDetailsData[this.specIndex].start_date;
      return commonHelper.disabledDates(date, refDate);
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
