import appStrings from '@/app/utility/string.utility';
import commonHelper from '@/app/utility/common.helper.utility';
import DatePicker from 'vue2-datepicker';
import meterAccessEntryForm from './meterAccessEntryForm';
import User from '../../admin/user';
export default {
  name: 'meterAccessRights',
  components: { DatePicker, meterAccessEntryForm, User },
  watch: {
    currentPage: function() {
      this.getMeterAccessDtls();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getMeterAccessDtls();
    }
  },
  data() {
    return {
      loader: false,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      totalRows: null,
      currentPage: 1,
      user: {
        text: null,
        value: null
      },
      project: {
        text: null,
        value: null
      },
      startDate: null,
      endDate: null,
      meterAccessRightsData: [],
      meterAccessRightsFields: [
        {
          key: 'user_name',
          label: 'User Name',
          class: 'text-center'
        },
        {
          key: 'user_email',
          label: 'User Email',
          class: 'text-center'
        },
        {
          key: 'employee_code',
          label: 'Employee Code',
          class: 'text-center'
        },
        {
          key: 'fms_prj_name',
          label: 'Project',
          class: 'text-center'
        },
        {
          key: 'start_date',
          label: 'Start Date',
          class: 'text-center'
        },
        {
          key: 'end_date',
          label: 'End Date',
          class: 'text-center'
        }
      ],
      accessButtons: {
        deleteAllow: false,
        downloadAllow: false,
        uploadAllow: false,
        editAllow: true,
        addAllow: true,
        printAllow: false,
        approvalAllow: false
      },
      rowData: null,
      unsubscribe: null,
      showMeterAccessFormModal: false,
      showUserModal: false,
      showValueSetModal: false,
      userCode: null
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.rowData = null;
          this.showMeterAccessFormModal = true;
        }
      }
    });
  },
  methods: {
    disabledDates(date) {
      return commonHelper.disabledDates(date, this.startDate);
    },
    rowSelected(item) {
      this.rowData = item;
      this.showHideMeterAccessFormModal(true);
    },
    showHideMeterAccessFormModal(flag) {
      this.showMeterAccessFormModal = flag;
    },
    showHideUserModal(flag) {
      this.showUserModal = flag;
    },
    selectedUser(item) {
      this.user.value = item.user_id;
      this.user.text = item.name;
      this.userCode = item.user_code;
      // this.user.email = item.user_email;
      this.showUserModal = false;
    },
    getMeterAccessDtls() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        user_id: this.user.value,
        fms_prj_id: this.project.value,
        start_date: this.startDate,
        end_date: this.endDate,
        emp_id: this.userCode
      };
      this.loader = true;
      this.$store
        .dispatch('energyDataInput/getMeterAccessDtls', payload)
        .then(response => {
          if (response.status === 200) {
            this.loader = false;
            this.meterAccessRightsData = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    clear() {
      this.user = {
        text: null,
        value: null
      };
      this.project = {
        text: null,
        value: null
      };
      this.startDate = null;
      this.endDate = null;
      this.meterAccessRightsData =[]

    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      if (
        this.vsetCode === appStrings.VALUESETTYPE.FMS_PRJ_INTERNAL_BY_USER_ID
      ) {
        this.parent_value_set_id = this.$store.state.auth.userId;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (
        this.vsetCode === appStrings.VALUESETTYPE.FMS_PRJ_INTERNAL_BY_USER_ID
      ) {
        this.project = {
          text: item.value_code,
          value: item.value_set_dtl_id
        };
      }
    },
    clearVsetValues(vsetCode) {
      this.vsetCode = vsetCode;
      if (this.vsetCode === 'USER') {
        this.user = {
          text: null,
          value: null
        };
        this.userCode = null;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.FMS_PRJ_INTERNAL_BY_USER_ID
      ) {
        this.project = {
          text: null,
          value: null
        };
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
